import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";

function ViewSequence() {
  let { sequenceId } = useParams();

  const [sequence, setSequence] = useState({});
  const [sequenceAgents, setSequenceAgents] = useState([]);
  const [agents, setAgents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRunLoading, setIsRunLoading] = useState(false);
  

  const [runResult, setRunResult] = useState(null);
  const [runData, setRunData] = useState({});

  useEffect(() => {
    const fetchDemo = async () => {
      setIsLoading(true);
      const response = await api.post(`/get_sequence`, { seq_id: sequenceId });
      setSequence(response.data.data);

      const seqAgents = await api.get(`/get_sequence_agents`);
      const seqAgentsData = seqAgents.data.data;
      setSequenceAgents(
        seqAgentsData.filter((agent) => agent.sequence_id === sequenceId)
      );

      const agentsResponse = await api.get("/get_agents");
      setAgents(agentsResponse.data.data);

      setIsLoading(false);
    };
    fetchDemo();
  }, [sequenceId]);

  const handleChange = (e, agentId, field) => {
    const { value } = e.target;
    setRunData((prevData) => ({
      ...prevData,
      [agentId]: {
        ...prevData[agentId],
        [field]: value,
      },
    }));
  };

  async function RunSequence() {
    setIsRunLoading(true);
    try {
      const sequenceData = {
        seq_id: sequenceId,
        user_id: "20173961-a991-4487-953f-4e47f887ef0d",
        user_prompt_source: [],
        user_prompt_target: [],
        website_url_source: [],
        website_url_target: [],
        file_url_source: [],
        file_url_target: [],
      };

      sequenceAgents.forEach((agent) => {
        const agentData = runData[agent.agent_id] || {};
        if (agent.role === 'source') {
          sequenceData.user_prompt_source.push({agent_id: agent.agent_id, data: agentData.user_prompt} || {});
          sequenceData.website_url_source.push({agent_id: agent.agent_id, data: agentData.website_url} || {});
          sequenceData.file_url_source.push({agent_id: agent.agent_id, data: agentData.file_url} || {});
        } else if (agent.role === 'target') {
          sequenceData.user_prompt_target.push({agent_id: agent.agent_id, data: agentData.user_prompt} || {});
          sequenceData.website_url_target.push({agent_id: agent.agent_id, data: agentData.website_url} || {});
          sequenceData.file_url_target.push({agent_id: agent.agent_id, data: agentData.file_url} || {});
        }
      });

      const response = await api.post("/run_sequence", sequenceData);
      const result = await response.data.results;
      let resultArray = Object.entries(result).map(([key, value]) => ({
        id: key,
        result: value,
      }));

      setRunResult(resultArray);
      window.scrollTo({ top: 1000, behavior: "smooth" })
      setIsRunLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>
      {isLoading && <p>Loading sequence...</p>}
      {!isLoading && (
        <>
          <h1>{sequence.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>{sequence.name}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>{sequence.description}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{sequence.created_at}</Col>
          </Row>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Agent Name</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {sequenceAgents.map((agent) => (
                <tr key={agent.id}>
                  <td>
                    <a href={`/workflows/agents/view/${agent.agent_id}`}>
                      {agents.find((a) => a.id === agent.agent_id)?.name}
                    </a>
                  </td>
                  <td>{agent.role}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4">
            <h3>Run Agent</h3>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Source Agents</th>
                  <th>Parameters</th>
                </tr>
              </thead>
              <tbody>
                {sequenceAgents.map((agent) => (
                  <tr key={agent.id}>
                    <td>
                      {agents.find((a) => a.id === agent.agent_id)?.name} (
                      {agent.role})
                    </td>
                    <td>
                      <Form.Group className="mb-3">
                        <Form.Label>User Prompt: </Form.Label>
                        <Form.Control
                          type="text"
                          name="user_prompt"
                          value={runData[agent.agent_id]?.user_prompt || ''}
                          onChange={(e) =>
                            handleChange(e, agent.agent_id, 'user_prompt')
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Website URL: </Form.Label>
                        <Form.Control
                          type="text"
                          name="website_url"
                          value={runData[agent.agent_id]?.website_url || ''}
                          onChange={(e) =>
                            handleChange(e, agent.agent_id, 'website_url')
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>File URL: </Form.Label>
                        <Form.Control
                          type="text"
                          name="file_url"
                          value={runData[agent.agent_id]?.file_url || ''}
                          onChange={(e) =>
                            handleChange(e, agent.agent_id, 'file_url')
                          }
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button
      variant="primary"
      disabled={isRunLoading}
      onClick={!isRunLoading ? RunSequence : null}
      className="my-4"
    >
      {isRunLoading ? 'Loading…' : 'Run sequence'}
    </Button>
          </div>

          {runResult && (
            <>
              <Row>
                <Col xs={2}>
                  <strong>Run Result: </strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Agent ID</th>
                        <th>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {runResult.map((result) => (
                        <tr key={result.id}>
                          <td>
                            <a href={`${result.id}`}>
                              {agents.find((a) => a.id === result.id)?.name}
                            </a>
                          </td>
                          <td>
                            <ReactMarkdown>{result.result}</ReactMarkdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default ViewSequence;
