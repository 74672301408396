import { react } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Workflows() {

    const navigate = useNavigate();

  return (
    <Container>
      <h1>Workflows</h1>

      <Row>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Sequences</Card.Title>
              <Card.Text>
                Create sequences of agents to automate workflows.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/sequences/list')}>View sequences</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Agents</Card.Title>
              <Card.Text>
                Create and manage agents to automate workflows.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/agents/list')}>View agents</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Customer Files</Card.Title>
              <Card.Text>
                Upload customer merged files for agents to use.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/agents/list')}>Add a new file</Button>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      
    </Container>
  );
}

export default Workflows;
