import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import api from "../../../utils/agentApi";

function ViewAgent() {
  let { agentId } = useParams();

  const [agent, setAgent] = useState({});
  const [userPrompt, setUserPrompt] = useState("");
  const [runResult, setRunResult] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchDemo = async () => {
      setIsLoading(true);
      const response = await api.post(`/get_agent`, { agent_id: agentId });

      setAgent(response.data.data);
      setIsLoading(false);
    };
    fetchDemo();
  }, [agentId]);

  async function RunAgent() {
    try {

        const agentData = {
            "agent_id": agentId,
            "user_prompt": userPrompt
        };
        
        const response = await api.post('/run_agent', agentData);
        const result = await response.data.result;
        setRunResult(result);
      }
      catch (error) {
          console.error(error);
      }
  }

  return (
    <Container>
      {isLoading && <p>Loading agent...</p>}
      {!isLoading && (
        <>
          <h1>{agent.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>{agent.name}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>{agent.description}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{agent.created_at}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Type: </strong>
            </Col>
            <Col>{agent.custom_code === null || agent.custom_code === "" ? "AI" : "Calculation"}</Col>
          </Row>
          {(agent.custom_code === null || agent.custom_code === "") && (
            <>
              {/*   
        "model": "gpt-4o",
        "frequency_penalty": 0,
        "max_tokens": null,
        "presence_penalty": 0,
        "response_format": "text",
        "stream": false,
        "temperature": 0,
        "system_prompt": "",
         */}
              <Row>
                <Col xs={2}>
                  <strong>Model: </strong>
                </Col>
                <Col>{agent.model}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Prompt: </strong>
                </Col>
                <Col>{agent.system_prompt}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Frequency Penalty: </strong>
                </Col>
                <Col>{agent.frequency_penalty}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Max Tokens: </strong>
                </Col>
                <Col>{agent.max_tokens}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Presence Penalty: </strong>
                </Col>
                <Col>{agent.presence_penalty}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Stream: </strong>
                </Col>
                <Col>{agent.stream ? "True" : "False"}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Response Format: </strong>
                </Col>
                <Col>{agent.response_format}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <strong>Temperature: </strong>
                </Col>
                <Col>{agent.temperature}</Col>
              </Row>
            </>
          )}
          {(agent.custom_code !== null || agent.custom_code !== "") && (
            <>
              <Row>
                <Col xs={2}>
                  <strong>Custom Code: </strong>
                </Col>
                <Col>{agent.custom_code}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Custom Input: </strong>
                </Col>
                <Col>
                {agent.custom_input.filePath}
                </Col>
              </Row>
            </>
          )}

            <div className="mt-4">
                <h3>Run Agent</h3>
                <Form.Group className="mb-3">
                    <Form.Label>User Prompt</Form.Label>
                    <Form.Control type="text" 
                    value={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.value)} />
                </Form.Group>

                <Button onClick={RunAgent}>Run</Button>
            </div>

            {runResult && (
                <Row>
                    <Col xs={2}>
                        <strong>Run Result: </strong>
                    </Col>
                    <Col><ReactMarkdown>{JSON.stringify(runResult)}</ReactMarkdown></Col>
                </Row>
            )}
        </>
      )}
      
    </Container>
  );
}

export default ViewAgent;
